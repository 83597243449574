import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import styles from '../SignIn.module.scss';
import {
  Button,
  display,
  flex,
  Input,
  Link,
  offsets,
  Typography
} from '@xq/ui-kit';
import classNames from 'classnames';
import { SignInService, SignInServiceApi } from '../sign-in-service';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  redirectToProfile,
  showErrorNotification,
  submitForm
} from '@services';
import { useTimer } from '@hooks';
import { TwoFactorAuth } from '@pages';

interface TwoFactorAuthFormProps {
  twoFactorAuthSettings: TwoFactorAuth;
  setTwoFactorAuthSettings: (twoFactorAuthSettings: TwoFactorAuth) => void;
}

export const TwoFactorAuthForm: FC<TwoFactorAuthFormProps> = (props) => {
  const { twoFactorAuthSettings, setTwoFactorAuthSettings } = props;
  const { twoFactorId, method, resendCodeDelayInSec } = twoFactorAuthSettings;
  const service: SignInService = new SignInServiceApi();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [oneTimePasscode, setOneTimePasscode] = useState<string>('');

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  const { seconds, startTimer, running } = useTimer({
    initialSeconds: resendCodeDelayInSec,
    initiallyRunning: true
  });

  useEffect(() => {
    setIsValidationError(false);
  }, [oneTimePasscode]);

  async function signInTwoFactor() {
    if (!oneTimePasscode) {
      return;
    }

    setIsLoading(true);

    try {
      await service.signInTwoFactor(twoFactorId, oneTimePasscode);
      redirectToProfile(redirectUrl);
    } catch (error) {
      if (
        error.error === 'IAM_TWO_FACTOR_ID_INVALID' ||
        error.error === 'IAM_TWO_FACTOR_CODE_INVALID'
      ) {
        setIsValidationError(true);
      } else {
        showErrorNotification(t, error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function resendTwoFactorCode() {
    if (running) {
      return;
    }

    try {
      const resendCodeDelayInSec =
        await service.resendTwoFactorCode(twoFactorId);
      setIsResetDisabled(true);
      startTimer(resendCodeDelayInSec);
      setOneTimePasscode('');
    } catch (error) {
      showErrorNotification(t, error);
    } finally {
      setIsResetDisabled(false);
    }
  }

  function cancel() {
    setTwoFactorAuthSettings(null);
  }

  return (
    <div>
      <Typography className={styles.header} element={'div'} variant="h3">
        {t('signIn.twoFactorAuthentication')}
      </Typography>

      <Typography
        element={'div'}
        variant={'body-1'}
        className={offsets['pb-20']}
      >
        {t('signIn.pleaseEnterOnTimePasscodeFrom')} {method}.
      </Typography>
      <form onSubmit={(e) => submitForm(e)}>
        <div className={offsets['mb-20']}>
          <Input
            className={offsets['mb-20']}
            value={oneTimePasscode}
            disabled={isLoading}
            errorMessage={
              isValidationError ? t('signIn.expiredOrInvalidPasscode') : ''
            }
            onChange={(value) => setOneTimePasscode(String(value))}
            label={t('signIn.oneTimePasscode')}
            inputMode={'numeric'}
            autoComplete={'off'}
            withIcon
          />
        </div>

        <div
          className={classNames(
            display['d-flex'],
            flex['justify-content-center'],
            offsets['mb-40']
          )}
        >
          <Button
            buttonType={'submit'}
            onClick={signInTwoFactor}
            isLoading={isLoading}
            disabled={!oneTimePasscode}
            className={offsets['mr-20']}
          >
            {t('signIn.verify')}
          </Button>
          <Button onClick={cancel} type={'secondary'} disabled={isLoading}>
            {t('signIn.cancel')}
          </Button>
        </div>

        <div
          className={classNames(
            display['d-flex'],
            flex['align-items-center'],
            flex['flex-col']
          )}
        >
          <Typography element={'div'} variant={'body-1'}>
            {t('signIn.dontReceiveYourCode')}
          </Typography>

          <div>
            {running ? (
              <Typography variant={'body-1'}>
                Wait <Typography variant={'number-1'}>{seconds}</Typography>s
                before requesting a new code
              </Typography>
            ) : (
              <div>
                <Link
                  disabled={isResetDisabled}
                  onClick={resendTwoFactorCode}
                  type="button"
                >
                  {t('signIn.resendPasscode')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

TwoFactorAuthForm.displayName = 'TwoFactorAuthForm';
