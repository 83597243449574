import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { SignInService, SignInServiceApi } from './sign-in-service';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { redirectToProfile, showErrorNotification } from '@services';
import { TwoFactorAuth } from '@pages/SignIn/dataTypes';
import { EmailPasswordForm } from '@pages/SignIn/EmailPasswordForm/EmailPasswordForm';
import { TwoFactorAuthForm } from '@pages/SignIn/TwoFactorAuthForm/TwoFactorAuthForm';

export const SignIn: FC = () => {
  const service: SignInService = new SignInServiceApi();
  const { t } = useTranslation();

  const [twoFactorAuthSettings, setTwoFactorAuthSettings] =
    useState<TwoFactorAuth>(null);

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  async function authCheck() {
    try {
      await service.authCheck();
      redirectToProfile(redirectUrl);
    } catch (error) {
      const status = error.status;
      if (status === 401) {
        return;
      } else {
        showErrorNotification(t, error);
      }
    }
  }

  useEffect(() => {
    authCheck();
  }, []);

  return (
    <div>
      {!twoFactorAuthSettings?.twoFactorId && (
        <EmailPasswordForm
          setTwoFactorAuthSettings={setTwoFactorAuthSettings}
        />
      )}

      {twoFactorAuthSettings?.twoFactorId && (
        <TwoFactorAuthForm
          twoFactorAuthSettings={twoFactorAuthSettings}
          setTwoFactorAuthSettings={setTwoFactorAuthSettings}
        />
      )}
    </div>
  );
};

SignIn.displayName = 'SignIn';
