import { handleBackendError, iamGateway, wait } from '@services';

export interface ResetPasswordForm {
  email: string;
}

export interface ResetPasswordService {
  resetPassword(model: ResetPasswordForm): Promise<void>;
}

export class ResetPasswordServiceApi implements ResetPasswordService {
  async resetPassword(model: ResetPasswordForm): Promise<void> {
    try {
      await iamGateway.authV2ControllerSendSetPasswordLink({
        authV2DTORequestSendSetPasswordLink: {
          email: model.email
        }
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.resetPassword.name
      );
    }
  }
}

export class ResetPasswordServiceMock implements ResetPasswordService {
  async resetPassword(model: ResetPasswordForm): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.resetPassword.name
      );
    }
  }
}
