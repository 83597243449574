import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import styles from '../SignIn.module.scss';
import {
  Button,
  Checkbox,
  display,
  flex,
  Input,
  Link,
  offsets,
  Typography,
  validate,
  ValidateTypes
} from '@xq/ui-kit';
import classNames from 'classnames';
import { SignInService, SignInServiceApi } from '../sign-in-service';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  redirectToProfile,
  redirectToURL,
  showErrorNotification,
  showSuccessNotification,
  submitForm
} from '@services';
import { TwoFactorAuth } from '@pages';

interface EmailPasswordFormProps {
  setTwoFactorAuthSettings: (twoFactorAuthSettings: TwoFactorAuth) => void;
}

export const EmailPasswordForm: FC<EmailPasswordFormProps> = (props) => {
  const { setTwoFactorAuthSettings } = props;
  const service: SignInService = new SignInServiceApi();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  const isFieldEmpty = (field: string): boolean => {
    return field.length === 0;
  };

  useEffect(() => {
    const isEmpty: boolean = isFieldEmpty(email) || isFieldEmpty(password);
    setDisabled(isEmpty);
  }, [email, password]);

  async function signIn() {
    const isEmailValidated = validate(ValidateTypes.email, email);
    setIsValidationError(!isEmailValidated);

    if (!isEmailValidated) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await service.signIn({ email, password, rememberMe });
      if (response?.changePasswordId) {
        navigate(
          `/update-password?changePasswordId=${response.changePasswordId}`
        );
        return;
      }

      setTwoFactorAuthSettings(response.twoFactor);

      if (!response?.twoFactor?.twoFactorId) {
        redirectToProfile(redirectUrl);
      } else {
        showSuccessNotification(
          t,
          <span>
            {t('signIn.passcodeHasBeenSentTo')} <b>{email}</b>
          </span>
        );
      }
    } catch (error) {
      showErrorNotification(t, error);
    } finally {
      setIsLoading(false);
    }
  }

  async function getLogInAzureAdLink() {
    try {
      const url = await service.getLogInAzureAdLink();
      redirectToURL(url, true);
    } catch (error) {
      showErrorNotification(t, error);
    }
  }

  return (
    <div>
      <Typography className={styles.header} variant="h3">
        <div>{t('signIn.signIn')}</div>
      </Typography>
      <form onSubmit={(e) => submitForm(e)}>
        <div className={offsets['mb-20']}>
          <Input
            className={offsets['mb-20']}
            value={email}
            disabled={isLoading}
            errorMessage={
              isValidationError ? t('notifications.incorrectEmail') : ''
            }
            onChange={(value) => setEmail(String(value))}
            label={t('common.email')}
            type="email"
            autoComplete={'login-email'}
            inputMode={'email'}
          />
          <Input
            value={password}
            disabled={isLoading}
            onChange={(value) => setPassword(String(value))}
            label={t('common.password')}
            type="password"
            withIcon
            autoComplete={'login-password'}
          />
        </div>
        <div
          className={classNames(
            display['d-flex'],
            flex['justify-content-between'],
            flex['align-items-center']
          )}
        >
          <Checkbox
            disabled={disabled || isLoading}
            checked={rememberMe}
            onChange={(value) => setRememberMe(value)}
            label={t('common.rememberMe')}
          />
          <Link href="reset-password" type="button">
            {t('common.forgotPassword')}
          </Link>
        </div>

        <div
          className={classNames(
            display['d-flex'],
            flex['justify-content-center'],
            offsets['mt-40']
          )}
        >
          <Button
            className={styles['block-button']}
            buttonType={'submit'}
            onClick={signIn}
            isLoading={isLoading}
            disabled={disabled}
          >
            {t('signIn.signIn')}
          </Button>
        </div>

        <div className={styles['continue-wrap']}>
          <div className={styles['continue-block']}>
            <Typography
              element={'div'}
              className={styles['continue-text']}
              variant={'body-1'}
            >
              {t('signIn.orContinueWith')}
            </Typography>
          </div>

          <hr className={styles.divider} />
        </div>

        <div
          className={classNames(
            display['d-flex'],
            flex['justify-content-center'],
            offsets['mt-40']
          )}
        >
          <Button
            type={'secondary'}
            className={styles['block-button']}
            onClick={getLogInAzureAdLink}
          >
            {t('signIn.azureAD')}
          </Button>
        </div>
      </form>
    </div>
  );
};

EmailPasswordForm.displayName = 'EmailPasswordForm';
