import React, { FC, useEffect, useState } from 'react';
import styles from './ResetPassword.module.scss';
import {
  Button,
  DEFAULT_NOTIFICATION_DELAY,
  display,
  flex,
  Input,
  offsets,
  openNotification,
  Typography,
  validate
} from '@xq/ui-kit';
import classNames from 'classnames';
import {
  ResetPasswordService,
  ResetPasswordServiceApi
} from './reset-password-service';
import { useTranslation } from 'react-i18next';
import { showErrorNotification, submitForm } from '@services';

export const ResetPassword: FC = () => {
  const service: ResetPasswordService = new ResetPasswordServiceApi();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);

  const isFieldEmpty = (field: string): boolean => {
    return field.length === 0;
  };

  useEffect(() => {
    const isEmpty: boolean = isFieldEmpty(email);
    setDisabled(isEmpty);
  }, [email]);

  async function resetPassword() {
    const isEmailValidated = validate('email', email);
    setIsValidationError(!isEmailValidated);

    if (!isEmailValidated) {
      return;
    }

    setIsLoading(true);

    try {
      await service.resetPassword({ email });
      setEmail('');
      openNotification(
        <span>
          {t('resetPassword.resetMailSentPart1')} <strong>{email}</strong>{' '}
          {t('resetPassword.resetMailSentPart2')}
        </span>,
        'success',
        DEFAULT_NOTIFICATION_DELAY
      );
    } catch (error) {
      showErrorNotification(t, error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Typography className={styles.header} variant="h3">
        {t('resetPassword.resetPassword')}
      </Typography>

      <Typography variant="body-1">{t('resetPassword.description')}</Typography>

      <form onSubmit={(e) => submitForm(e)}>
        <div className={offsets['my-20']}>
          <Input
            className={offsets['mb-20']}
            value={email}
            disabled={isLoading}
            errorMessage={
              isValidationError ? t('notifications.incorrectEmail') : ''
            }
            onChange={(value) => setEmail(String(value))}
            label={t('common.email')}
            type="email"
          />
        </div>

        <div
          className={classNames(
            display['d-flex'],
            flex['justify-content-center'],
            offsets['mt-40']
          )}
        >
          <Button
            buttonType={'submit'}
            onClick={resetPassword}
            isLoading={isLoading}
            disabled={disabled}
          >
            {t('common.send')}
          </Button>
        </div>
      </form>
    </div>
  );
};

ResetPassword.displayName = 'ResetPassword';
