import { handleBackendError, iamApiConfiguration, wait } from '@services';
import { appVersion } from '@constants';
import { AuthAzureADWebApi } from '@xq/iam-frontend-client';

export interface AzureAdService {
  finishAzureADLogIn(code: string): Promise<void>;
}

const iamAuthAzureADGateway = new AuthAzureADWebApi(iamApiConfiguration);

export class AzureAdServiceApi implements AzureAdService {
  async finishAzureADLogIn(code: string): Promise<void> {
    try {
      return await iamAuthAzureADGateway.authAzureADWebControllerFinishLogIn({
        code: code,
        xqAppVersion: appVersion?.version,
        xqAppName: appVersion?.name
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.finishAzureADLogIn.name
      );
    }
  }
}

export class AzureAdServiceMock implements AzureAdService {
  async finishAzureADLogIn(code: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.finishAzureADLogIn.name
      );
    }
  }
}
