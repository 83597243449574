import {
  openStatusNotification,
  StatusNotificationTranslations
} from '@xq/ui-kit';
import { TFunction } from 'i18next';
import { BackendErrorResponse } from '@interfaces';
import * as React from 'react';

export const getStatusNotificationTranslations = (
  t: TFunction
): StatusNotificationTranslations => {
  return {
    savedSuccessfully: t('notifications.savedSuccessfully'),
    badRequest: t('notifications.badRequest'),
    internalServerError: t('notifications.internalServerError')
  };
};

export const showSuccessNotification = (
  t: TFunction,
  message?: React.ReactNode
) => {
  openStatusNotification({
    translations: getStatusNotificationTranslations(t),
    status: 200,
    message: message
  });
};

export const showErrorNotification = (
  t: TFunction,
  error: BackendErrorResponse
) => {
  const translationFromErrorCode = handleTranslationErrorFromStatus(
    t,
    error?.error
  );
  openStatusNotification({
    translations: getStatusNotificationTranslations(t),
    status: error?.status,
    error: {
      details: error?.details,
      code: error?.error,
      message: translationFromErrorCode || error?.message
    }
  });
};

export const handleTranslationErrorFromStatus = (
  t: TFunction,
  error: string
): string => {
  switch (error) {
    case 'IAM_USER_NOT_FOUND': {
      return t('signIn.incorrectEmailOrPassword');
    }
    case 'IAM_USER_IS_BLOCKED': {
      return t('signIn.userIsBlocked');
    }
    case 'IAM_SET_PASSWORD_TOKEN_NOT_FOUND': {
      return t('signIn.passwordTokenNotFound');
    }
    case 'IAM_USER_IS_TEMPORARY_LOCKED': {
      return t('signIn.userIsTemporaryBlocked');
    }
    default: {
      return null;
    }
  }
};
