import { handleBackendError, iamGateway, wait } from '@services';

export interface UpdatePasswordServiceForm {
  password: string;
  changePasswordId: string;
}

export interface UpdatePasswordService {
  setPassword(model: UpdatePasswordServiceForm): Promise<void>;
}

export class UpdatePasswordServiceApi implements UpdatePasswordService {
  async setPassword(model: UpdatePasswordServiceForm): Promise<void> {
    try {
      await iamGateway.authV2ControllerSetPassword({
        changePasswordId: model.changePasswordId,
        authV2DTORequestSetPassword: { password: model.password }
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.setPassword.name
      );
    }
  }
}

export class UpdatePasswordServiceMock implements UpdatePasswordService {
  async setPassword(model: UpdatePasswordServiceForm): Promise<void> {
    try {
      /* api call */
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.setPassword.name
      );
    }
  }
}
